.coming_soon .bg {
 position: relative;
 width: 100%;   
 overflow: hidden;
 object-fit: contain;
}

.coming_soon .bg img {
    object-fit: cover;
    width: 100%;
    min-height: 448px;
}

.coming_soon .context {
    position: absolute;
    text-align: start;
    left: 7%;
    width: 33%;
    top: 0px;
    Transform: translate(0%, 15%)
}

.coming_soon .glogo {
    max-width: 100%;
    
}


@media (max-width: 767.98px) {
    .coming_soon .bg {
        /* height: 80vh; */
        /* background-color: red; */
        
    }
    .coming_soon .bg img {
        position: relative;
        object-fit: cover;
        
        /* width: 150vw !important; */
        object-position: 83% 0% !important;
    }

    .coming_soon .context {
        position: relative;
        text-align: center;
        left: unset;
        width: 100%;
        transform: unset;
        margin-top: -10%;
        background-image: linear-gradient(180deg, #00000000 , #ffffff 10%);
    }

    .coming_soon .glogo {
        max-width: 140px;
    }
}