.App {
  text-align: center;
  white-space: pre-line;
  font-family: 'Rubik', 'IBM Plex Sans Thai', sans-serif;
  padding: 0px;
  margin: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

label{
  text-align: start;
  font-weight: bold;
  color: #747474;
}

/* input {
  background-color: #e0e0e0 !important;
  color: #747474 !important;
  border: none !important;
  border-radius: 5px;
}

input:focus {
  background-color: #e0e0e0 !important;
  color: #747474 !important;
  border: none !important;
  border-radius: 5px;
} */

/* gmfield */

div.gmfield div {
  background-color: #e0e0e0;
  color: #747474;
}

label.gmfield {
  color: #424242;
}

div.gmfield input {
  color: #424242;
}

div.gmfield div.MuiSelect-select {
  text-align: start;
  color: #424242;
}



/* form-field > decprecate move to gmfield */

.form-field div {
  background-color: #e0e0e0;
  color: #747474;
}

.form-field input{
  /* border: none !important; */
  /* border-radius: 5px; */
  /* padding: 0.5rem;
  width: 100%; */
}

.form-field fieldset {
  /* background-color: blue; */
  /* margin: 0px !important; */
  /* padding: 0px !important; */
  border-color: #e0e0e0;
}

.form-field .Mui-focused fieldset {
  /* border-color: #e0e0e0 !important; */
}

.form-field div.Mui-error.Mui-focused fieldset {
  /* border-color: red !important; */
}

/* Custom mui error text color */
/* .Mui-error {
  color: green !important;
} */


.maxw-576, .maxw-sm{
  max-width: 576px;
}

.maxw-768, .maxw-md{
  max-width: 768px;
}

.maxw-992, .maxw-lg{
  max-width: 992px;
}

.maxw-1200, .maxw-xl{
  max-width: 1200px;
}

.maxw-1400, .maxw-xxl{
  max-width: 1400px;
}

.maxw-1920, .maxw-xxxl{
  max-width: 1920px;
}


/*---------------------- span ------------------------*/


span {
  white-space:pre-line;
  align-items: start;
  
  font-style: normal;
  font-weight: 400;
  font-size: max(min(22px, 1.14vw), 14px);
  color: #747474;
}

.lang-th {
  font-family: 'Kanit', 'IBM Plex Sans Thai', 'Rowdies', sans-serif;
}

.lang-en {
  font-family: 'Rubik', 'IBM Plex Sans Thai', sans-serif;
  
}

.lang-en.h1, .lang-en h1, .lang-en.h2, .lang-en h2 {
  font-family: 'Rowdies', 'IBM Plex Sans Thai', sans-serif;
}


h1, .h1 {
  font-weight: 700;
  font-size: max(min(96px, 5vw), 36px);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h2, .h2 {
  font-weight: 700;
  font-size: max(min(40px, 2vw), 24px);
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);  */
}

h2.textshadow, .h2.textshadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h3, .h3 {
  font-weight: 400;
  font-size: max(min(32px, 1.6vw), 18px);
}

h3.textshadow, .h3.textshadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h3.bold, .h3.bold {
  font-weight: 700 !important;
}

h4, .h4 {
  font-weight: 400;
  font-size: max(min(22px, 1.16vw), 14px);
}

h4.textshadow, .h4.textshadow {
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

h4.bold, .h4.bold {
  font-weight: 700 !important;
}

h5, .h5 {
  font-weight: 400;
  font-size: max(min(18px, 0.93vw), 14px);
}

h5.textshadow, .h5.textshadow {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

h5.bold, .h5.bold {
  font-weight: 700 !important;
}

h6, .h6 {
  font-weight: 400;
  font-size: max(min(14px, 0.9vw), 14px);
}

h6.bold, .h6.bold {
  font-weight: 700 !important;
}

.orange {
  color: #eb4335;
}
.bg-orange {
  background-color: #eb4335;
}

.black {
  color: #1a1a1a;
}
.bg-black {
  background-color: #1a1a1a;
}

.gray {
  color: #808080;
}
.bg-gray {
  background-color: #808080;
}

.white {
  color: #ffffff;
}
.bg-white {
  background-color: #ffffff;
}

.blue {
  color: #07b0ee;
}
.bg-blue {
  background-color: #07b0ee;
}

.purple {
  color: #7D2DFF;
}
.bg-purple{
  background-color: #7D2DFF;
}

.black26 {
  color: #262626;
}
.bg-black26 {
  background-color: #262626;
}

.gray74 {
  color: #747474;
}
.bg-gray74 {
  background-color: #747474;
}

.gray80 {
  color: #808080;
}
.bg-gray80 {
  background-color: #808080;
}

.dark42 {
  color: #424242;
}
.bg-dark42 {
  background-color: #424242;
}

.blueee {
  color: #07B0EE; 
}
.bg-blueee {
  background-color: #07B0EE; 
}

.red73 {
  color: #731b15;
}
.bg-red73 {
  background-color: #731b15;
}

.blue89 {
  color: #182489;
}
.bg-blue89 {
  background-color: #182489;
}

.purple89 {
  color: #2A0089;
}
.bg-purple89{
  background-color: #2A0089;
}

.blown46{
  color: #462513
}
.bg-blown46{
  background-color: #462513
}


/*-----------------------------------------------------*/

img.bg {
  position: static;
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* background-size: cover; */
}


div.lv1_grid{
  position: relative;
  min-height: 270px;
  /* width: 99%; */
  margin: auto;
  padding: auto;
  width: auto;
  /* background-color: #f6edc6; */
  overflow: hidden;
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  vertical-align: center;
  display: grid;   
}

div.lv1_flex{
  position: relative;
  min-height: 270px;
  display: flex;  
  /* width: 99%; */
  margin: auto;
  padding: auto;
  width: 100%;
  /* background-color: #f6edc6; */
  overflow: hidden;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  /* vertical-align: center; */
   
}

/* --------------------- Button ---------------------*/

button.blue_box, button.white_box, button.tran_box {
  box-sizing: border-box;
  border-radius: 5px;
  /* padding: 0.40vw; */
  padding-left: 1vw;
  padding-right: 1vw;
  margin: 1px;
  /* line-height: max(min(22px, 1.14vw), 12px); */

  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 700;
  font-size: max(min(18px, 0.93vw), 14px);
  /* width: 180px; */
  min-width: max(min(140px, 14.6vw), 80px);
  /* 120px; */
  min-height: 40px;
  height: max(min(56px, 2.9vw), 46px);
  max-height: 56px;
  
  /* identical to box height */
  display: inline;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

button.blue_sbox, button.white_sbox, button.tran_sbox {
  box-sizing: border-box;
  border-radius: 5px;
  /* padding: 0.40vw; */
  padding-left: 1vw;
  padding-right: 1vw;
  margin: auto;
  /* line-height: max(min(22px, 1.14vw), 12px); */

  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 700;
  font-size: max(min(18px, 0.93vw), 14px);
  /* width: 180px; */
  min-width: max(min(140px, 14.6vw), 80px);
  /* 120px; */
  min-height: 32px;
  height: max(min(48px, 2.5vw), 40px);
  max-height: 52px;
  
  /* identical to box height */
  display: inline;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 767.98px) {
  button.blue_box, button.white_box, button.tran_box {
    /* font-size: 1px; */
    /* padding: 1px; */
    height: 40px;
  }

  button.blue_sbox, button.white_sbox, button.tran_sbox {
    height: 32px;
  }
}

button.blue_box, button.blue_sbox{
  background: #07B0EE;
  border: 3px solid #07B0EE;
  color: #FFFFFF;
}

button.tran_box, button.tran_sbox{
  border: 3px solid #07B0EE;
  background-color: transparent;
  color: #424242;
}

button.white_box, button.white_sbox{
  border: 3px solid white;
  background-color: white;
  color: #424242;
}

button.blue_box:hover, button.blue_sbox:hover {
  background-color: #40c5f5;
  border-color: #40c5f5;
}

button.white_box:hover, button.white_sbox:hover {
  border-color: #40c5f5;
}

.shadow25{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* button.blue_box:disabled{
  background: #424242;
  border: 3px solid #424242;
} */

/* -----------------------------------------------------*/


/*------------------- Animation -------------------------------*/

.transi_ease_in_out {
  -webkit-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.75s ease-in-out;
  -ms-transition: all 0.75s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  transition: all 0.75s ease-in-out;
}

.ani_fade_in {
  animation: 2s ease-out 0s 1 ani_fadeIn;
  flex: auto;
  /* background-color: red; */
}


@keyframes ani_fadeIn {
  0% {
    transform: translateX(0%); opacity: 0;
  }
  100% {
    transform: translateX(0); opacity: 1;
  }
}

