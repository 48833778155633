
.reset_password .context {
    position: relative;
}

.reset_password .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -10;
    height: 100% !important;
    width: 100% !important;
    margin: 0px;
}

.reset_password .inner_box {
    max-width: 450px;
    min-height: 50vh;
    background-color: white;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border-radius: 10px;
    transition: all .3s;
}

@media (max-width: 576px) {
    .reset_password .inner_box {
        box-shadow: none;
    }
}

.reset_password .form-div {
    text-align: start;
}

.reset_password .form-div a {
    /* color: red; */
    text-decoration: underline;
    color: #07b0ee;
    font-weight: bold;
}

.reset_password .form-error {
    font-size: 0.75rem;
    color: red;
}

.reset_password .loader {
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: #00000055;
}

.reset_password .loader .progress {
    position: relative;
    width: 64px;
    height: 64px;
    /* top: calc(50% - 32px);
    left: calc(50% - 32px); */
}