/* Rectangle 1453 */
.home {
    /* height: 100%;
	width: 100%;
	min-height: 40em;
	margin: auto;
	border: solid 0px #000;
	object-fit: cover;
	display: block;
	text-align: center;
	vertical-align: middle; */
    /* background-color: red; */
    display: block;
    
	
	/* background-image: url('images/Under_Construction.jpg'); */
	/* background-size: cover;
	background-position: center;
	background-repeat: no-repeat; */
    
}

a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
}

/******************************* home_p1 ****************************/

.home .group3545 {
    position: absolute;
    width: 100%;
    /* margin-top: 0%; */
    margin-left: 7.86%;
    /* line-height: 4vw; */
    /* padding: 5%; */
    /* background-color: rgba(0,0,0,0.5); */
    display: block;
    /* justify-content:flex-start; */
    text-align: start;
    
}

.home .group3545 div.text1 {
    /* G MILLION */

    /* position: absolute; */
    /* left: 8.91%; */
    /* right: 74.27%; */
    /* top: 8.44%; */
    /* bottom: 89.99%; */

    /* ิblue cl */
    /* color: #ffffff; */

    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.home .group3545 div.text2 {
    /* SMILE POWER */

    /* position: absolute; */
    /* left: 8.91%; */
    /* right: 68.39%; */
    /* top: 10.47%; */
    /* bottom: 87.96%; */

    /* Gray Text */
    /* color: #262626; */

}

.home .group3545 div.text3 {
    /* Because of your smile and fun is "Our Happiness" */

    /* position: absolute; */
    /* left: 8.91%; */
    /* right: 53.23%;
    top: 12.41%;
    bottom: 86.35%; */

    /* Gray Text */
    /* color: #ffffff; */
    /* background-color: aqua; */
}

.home .group3545 div.text3 .black{
    /* color: black; */
}

.home img.bg {
    min-height: unset;
}

.home .prop-holder {
    position: absolute;
    width: 100%;
    min-height: unset;
    height: 100%;
}

.home img.bg-prop {
    position: absolute;
}

.home img.bg-prop.p1 {
    top: -2vw;
    right: -7vw;
    width: 59vw;
}

.home img.bg-prop.p2 {
    visibility: visible;
    top: 35vw;
    right: 51vw;
    width: 16.98vw;
}

.home img.bg-prop.p3 {
    visibility: visible;
    top: 7vw;
    right: 50vw;
    width: 9.9vw;
}

@media (max-width: 767.98px) {
    .home img.bg {
        min-height: 768px;
        height: 75vh;
    }

    .home .prop-holder {
        min-height: 768px;
        height: 75vh;
    }

    .home img.bg-prop.p1 {
        object-fit: cover;
        top: -2vw;
        right: 0;
        height: 70%;
        width: 100vw;
        object-position: 50% 100% !important;
    }

    .home img.bg-prop.p2 {
        visibility: hidden;
    }

    .home img.bg-prop.p3 {
        visibility: hidden;
    }

    .home .group3545 {
        top: 65%;
        left: 0%;
        /* left: 50%; */
        margin: 0px;
        /* width: 100%; */
        /* transform: translate(-50%, 0%); */
        align-items: center;
        align-content: center;
        align-self: center;
        text-align: center;
    }

    .home .group3545 div.text3 {
    /* text-align: center !important; */
    /* align-items: center !important; */
    /* align-self: center !important; */
    /* background-color: rebeccapurple; */
    }
    
}

/******************************* home_p2 ****************************/


.home .group3557 {
    position: absolute;
    left: 50%;

    width: 40%;
    /* top: 0%; */

    display:grid;
    text-align: left;
    align-items: left;
    justify-content: left;
    vertical-align: top;
    /* background-color: red; */
}

.home .img4575 {
    width: 100%;
}

.home .group3557_content {
    display:grid;
}

@media (max-width: 767.98px) {
    .home .home_p2 {
        height: 70vh;
        display: flex;
        justify-content: center;
    }

    .home .home_p2 .bg {
        object-fit: cover;
        opacity: 0.5;
        /* width: auto; */
        /* height: 100% !important; */
        object-position: 25% 100% !important;
        /* filter: brightness(0.5); */
    }

    .home .group3557 {
        left: 7.5%;
        width: 85%;
    }

    .home .group3557bg {
        position: relative;
        object-fit: contain;
        height: 100%;
    }
}

/******************************* home_p3 ****************************/

#home_p3{
    /* background-color: red; */
    z-index: -5;
    display: flex;
}

.project {
    position: relative;
    /* display: flex; */
    /* display:contents; */
    /* text-align: center; */
    /* align-items: center; */
    justify-content: center;
    vertical-align: center;
    /* height: 115vh; */
    top: 0;
    margin: 0;
    /* width: 33%; */
    /* background-color: red; */
}


.project_bg {
    position: relative;
    /* left: 0; */
    /* height: 100%; */
    width: 100%;
    /* margin: 0px auto; */
    /* width: 100%; */
    /* width: 50%; */
    /* object-fit: none; */
    z-index: -1;
    /* overflow: hidden; */
    /* height: auto; */
    /* background-color: red; */
}

.project_container {
    position: absolute;
    display: inline;
    left: 0;
    /* width: 100%; */
    height: 100%;
    justify-content: center;
    /* background-color: black; */
    z-index: 1;
    object-fit: fill;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
    overflow: hidden;
    
}

.project_logo {
    /* position: absolute; */
    display: block;
    width: 100%;
    justify-content: center;
    /* height: 25%; */
    /* top: 10%; */
    object-fit: fill;
    /* color: red; */
    /* background-color: red; */
}

img.plogo{
    position: relative;
    height: 12vw;
    max-width: 100%;
}

.project_content {
    max-height: 80%;
    /* background-color: blue; */
    display: grid;
    overflow: hidden;
}

.platform_dw {
    position: absolute;
    /* left: 50%; */
    left: 0;
    width: 100%;
    bottom: 2vw;
    /* background-color: red; */
}

.platform_dw img {
    max-height: 4vw;
    /* background-color: red; */
}

/******************************* home_p4 ****************************/
.group3559 {
    position: absolute;
    left: 7.86%;
    /* top: 15%; */
    width: 30%;
    z-index: 1;

    display:grid;
    text-align: left;
    align-items: left;
    /* justify-content: center; */
    vertical-align: top;
}

.group3559_button{
    display: flex;
}

.group3559 .imgglogo {
    width: 55%;
    object-fit: fill;
    /* width: 100%; */

}


@media (max-width: 767.98px) {
    .group3559 {
        position: relative;
        left: 10%;
        top: 0%;
        width: 80%;
        text-align: center;
        align-items: center;
    }

    /* .group3559 .imgglogo {
        visibility: collapse;
    } */

    img.group3559bg {
        position: relative;
        object-fit: cover;
        height: 50vh !important;
        min-height: 50vh !important;
        max-height: 100vh !important;
        object-position: 85% 100% !important;
    }
}

