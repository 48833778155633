.header {
    /* background-color: rgba(255, 255, 255, 0.8); */
    background-color: white;
}

.header-lg {
    width: 100%;
    /* background-color: #00ff0044; */
}



img.logo {
    object-fit: cover;
    height: 28px !important;
    max-height: 28px !important;
    cursor: pointer;
    /* -o-box-shadow:      12px 12px 29px rgba(0,0,0, 0.25);;
    -icab-box-shadow:   12px 12px 29px rgba(0,0,0, 0.25);;
    -khtml-box-shadow:  12px 12px 29px rgba(0,0,0, 0.25);;
    -moz-box-shadow:    12px 12px 29px rgba(0,0,0, 0.25);;
    -webkit-box-shadow: 12px 12px 29px rgba(0,0,0, 0.25);;
    box-shadow:         12px 12px 29px rgba(0,0,0, 0.25);; */

    /* -webkit-filter: drop-shadow(2px 2px 2px rgba(0,0,0, 0.25)); */
    /* filter: drop-shadow(2px 2px 2px rgba(0,0,0, 0.25));; */
}

img.lang {
    max-height: 24px;
    height: 24px;
    cursor: pointer;
}

.header .dropdown-toggle {
    /* color: #ffffff; */
    background-color: transparent;
    color: #808080;
    border: none;
}

.header .dropdown-toggle:focus {
    background-color: transparent;
    color: #808080;
}

.header .dropdown-menu.show {
    position: absolute;
    right: -0.75rem;
    border: none;
    height: 100vh;

    /* background-color: red; */
}

.header .dropdown-item.gray {
    background-color: #f8f8f8;
}

.header .hamburger {
    border: none !important;
    height: 40px !important;
    max-height: 40px !important;
}

.header .hamburger:focus, .header .hamburger:hover {
    border: none !important;
    box-shadow: none;
}

.header .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: -1;
}

.header .hamburger-menus {
    position: relative;
    pointer-events: all;
    
}


.header .hamburger-menus-inner {
    position: relative;
    width: 100%;
    /* top: -7%; */
    /* top: 10px; */
    height: calc(100vh - 56px) !important;
    color: white;
}

.header.isToggle {
    /* position: sticky; */
    /* height: calc(100vh ) !important; */
}

/* .header .hamburger-logo {
    width: 62.79%;
    max-width: 274px;
} */

