.footer{
    display: block;
}

#payment_content {
    max-width: 50%;
    /* height: auto; */
}

.footer .footer-inner {
    /* background-color: blue; */
}

.footer .address {
    /* background-color: #FF000044; */
    align-items: start;
    align-content: start;
    text-align:  start;
}

.footer .email2 {
    text-align: start;
}

.copyright {
    /* © 2023 G MILLION Co., Ltd. All Rights Reserved. */
    font-style: normal;
}

@media (max-width: 767.98px) {
    .copyright {
        color: #747474 !important;
    }

    .footer .address {
        align-items: center !important;
        align-content: center !important;
        text-align:  center !important;
    }
}


/* .copyright.th {
    color: red;
} */

#footer_body {
    min-width: 75%;
    max-width: 90%;
}

span.content {
    color: #424242;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    animation: 2s ease-out 0s 1 ani_fadeIn;

}

.divider {
    width: 75%;
}

#social {
    /* max-height: 1v/sw; */
    max-width: 55%;
}

.footer .isocial {
    max-height: 40px;
    /* max-width: 20px; */
}