.home .projects {
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: end;
    /* background-color: #0000ff55; */

    /* background-image: url('/img/home/projects_bg.jpg'); */
    background-repeat: repeat-y;
    background-size: contain;
}

.home .projects .info {
    /* background-color: #00ff0055; */
    font-family: 'Rowdies', 'IBM Plex Sans Thai', sans-serif;
}

.home .projects .info .target {
    font-size: max(min(64px, 3.3vw), 32px);
}

@media (max-width: 767.98px) {
    .home .projects .info .target {
        font-size: max(min(32px, 3.9vw), 18px);
    }
}

.home .projects .timeline {
    position: relative;
    /* background-color: #ff000055; */
}

.home .projects .timeline .theline {
    /* position: absolute; */
    /* left: calc(50% - 8px); */

    height: 100%;
    width: 0.75vw;
    background-color: #07b0eeff;
}

.home .projects .timeline .theline.start {
    background-image: linear-gradient(180deg, #00000000 3%, #07b0eeff 45%);
    background-color: unset;
}

.home .projects .timeline .theline.end {
    background-image: linear-gradient(180deg, #07b0eeff 55%, #00000000 97% );
    background-color: unset;
}

.home .projects .timeline .timeline_dot {
    position: absolute;
    left: calc(50% - 1.5vw);
    top: calc(50% - 1.5vw);
    width: 3vw;
}